<template>
  <svg preserveAspectRatio="xMidYMid" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle :stroke="color" cx="50" cy="50" fill="none" r="32"
            stroke-dasharray="50.26548245743669 50.26548245743669" stroke-linecap="round" stroke-width="8">
      <animateTransform attributeName="transform" dur="1s" keyTimes="0;1" repeatCount="indefinite" type="rotate"
                        values="0 50 50;360 50 50"></animateTransform>
    </circle>
  </svg>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    dark: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    color() {
      return `#${this.$props.dark ? '000' : 'fff'}`
    }
  }
}
</script>

<style scoped>
svg {
  margin: auto;
  display: block;
  width: 21px;
  height: 21px;
}
</style>

<template>
  <v-stepper v-model="step" alt-labels>
    <v-stepper-header>
      <v-stepper-step step="1" :complete="step > 1">COORDONNEES</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="2" :complete="step > 2">LIVRAISON</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="3" :complete="step > 3">PAIEMENT</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="4" :complete="step > 4">MERCI</v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  name: "Timeline",
  props: {
    step: {
      type: Number,
      default: 1
    }
  }
}
</script>

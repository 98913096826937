<template>
  <div v-if="orderDatas" id="merci">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>VOTRE COMMANDE ET VOTRE PAIEMENT ONT BIEN ÉTÉ PRIS EN COMPTE</h2>
          <h4 class="text-center">À NOUS DE JOUER</h4>
        </v-col>
        <v-col cols="12">
          <p>
            Connectez-vous à votre compte pour
            <span v-if="['colissimo', 'mr'].includes(orderDatas.detail_transport)">retrouver votre étiquette d'expédition,</span>
            suivre l'avancé de votre commande, retrouver votre facture...
          </p>
          <p class="text-center">
            <a :href="accountUrl" target="_blank">
              <v-btn dark>
                <v-icon class="mr-2" dark>
                  mdi-account-circle
                </v-icon>
                Accéder à mon compte
              </v-btn>
            </a>
          </p>
        </v-col>
        <v-col v-if="orderDatas.detail_transport=='atelier'" cols="12">
          <h6>1 - PRÉPAREZ VOTRE COLIS</h6>
          <p class="float-left">
            <img class="float-left" src="https://www.lesreparables.fr/cv2/clothetype/cart/envoi-colis.png"
                 style="width:100px;margin-right:10px;"/>
            Rassemblez vos vêtements propres dans un contenant adapté.
            Inscrivez vos coordonnées sur votre colis et déposez-le aux horaires du lieu correspondant.
          </p>
        </v-col>
        <v-col v-if="['pr', 'mr'].includes(orderDatas.detail_transport)" cols="12">
          <h6>1 - PRÉPAREZ VOTRE COLIS</h6>
          <p class="float-left">
            <img class="float-left" src="https://www.lesreparables.fr/cv2/clothetype/cart/envoi-colis.png"
                 style="width:100px;margin-right:10px;"/>
            Rassemblez vos vêtements propres dans un contenant adapté.
            Inscrivez vos coordonnées sur votre colis et déposez-le aux horaires du point partenaire que
            vous avez
            choisi.
          </p>
        </v-col>
        <v-col v-if="orderDatas.detail_transport=='colissimo'" cols="12">
          <h6>1 - PRÉPAREZ VOTRE COLIS</h6>
          <p class="float-left">
            <img class="float-left" src="https://www.lesreparables.fr/cv2/clothetype/cart/envoi-colis.png"
                 style="width:100px;margin-right:10px;"/>
            Rassemblez vos vêtements propres dans un contenant adapté.
            Collez votre étiquette reçue par mail et déposez-le à la poste.
          </p>
        </v-col>
        <v-col cols="12">
          <h6>2 - NOUS RÉPARONS</h6>
          <p>
            <img class="float-left"
                 src="https://www.lesreparables.fr/cv2/clothetype/cart/couture-depiquee.png"
                 style="width:100px;margin-right:10px;"/>
            ...au sein de nos ateliers situés en Vendée et à Lyon. Vos articles vous seront ensuite renvoyés
            dans le colis par
            lequel ils sont arrivés, pour éviter les déchets inutiles.
          </p>
        </v-col>
        <v-col v-if="orderDatas.detail_transport=='atelier'" cols="12">
          <h6>3 - JE LIVRE, TU LIVRES, IL LIVRE...</h6>
          <p>
            <img class="float-left" src="https://www.lesreparables.fr/cv2/clothetype/cart/livraison.png"
                 style="width:100px;margin-right:10px;"/>
            Une fois les réparations terminées, vous êtes averti par email. Vous pouvez venir récupérer
            votre colis à
            l’atelier.
          </p>
        </v-col>
        <v-col v-if="['pr', 'mr'].includes(orderDatas.detail_transport)" cols="12">
          <h6>3 - JE LIVRE, TU LIVRES, IL LIVRE...</h6>
          <p>
            <img class="float-left" src="https://www.lesreparables.fr/cv2/clothetype/cart/livraison.png"
                 style="width:100px;margin-right:10px;"/>
            Une fois les réparations terminées, vous êtes averti par email. Votre colis sera à nouveau
            disponible dans
            votre point de retrait quelques jours plus tard.
          </p>
        </v-col>
        <v-col v-if="orderDatas.detail_transport=='colissimo'" cols="12">
          <h6>3 - JE LIVRE, TU LIVRES, IL LIVRE...</h6>
          <p>
            <img class="float-left" src="https://www.lesreparables.fr/cv2/clothetype/cart/livraison.png"
                 style="width:100px;margin-right:10px;"/>
            Votre colis est en cours d'acheminement, il devrait arriver d'ici quelques jours après avoir
            quitté nos
            ateliers, chez vous.
          </p>
        </v-col>
        <v-col cols="12">
          <h6>4 - UN VÊTEMENT COMME NEUF</h6>
          <p>
            <img class="float-left" src="https://www.lesreparables.fr/cv2/clothetype/cart/reparateurs.png"
                 style="width:100px;margin-right:10px;"/>
            Reportez vos pièces favorites comme avant. Et n'oubliez-pas de partager votre expérience autour
            de vous!
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axios from 'axios'
import config from '../../config'
import {mapMutations, mapState} from "vuex";

export default {
  data() {
    return {
      orderDatas: null,
      accountUrl: config.accountUrl
    }
  },
  computed: {
    ...mapState('environment-app', ['environment']),
  },
  methods: {
    ...mapMutations('cart-app', ['resetCart']),
    getOrder() {
      return new Promise(() => {
        axios({
          url: `${config.apiHost}/get-order/${this.$route.query.orderid}`,
          method: 'GET',
          responseType: 'json',
        }).then((response) => {
          this.orderDatas = response.data.order
        })
      })
    },
  },
  created() {
    window.parent.postMessage({event: 'items-count', count: 0}, '*');
    window.parent.postMessage({event: 'order-paid'}, '*')
    this.resetCart()

    if (this.environment.redirectUrlAfterPayment) {
      window.location = this.environment.redirectUrlAfterPayment
    }

    this.getOrder()
  }
}
</script>
<style>
#merci h6 {
  margin-bottom: 20px;
}
</style>

<template>
  <div id="livraison">
    <v-row v-if="!user || !user.access_token">
      <v-col class="text-center" cols="12">
        Si vous avez déjà passé commande, vous pouvez vous connecter à votre compte client afin d'utiliser vos
        informations
      </v-col>
      <v-col class="text-center" cols="12">
        <a :href="`${accountHost}/authentication?redirect=${encodeURIComponent(`${currentHost}/#/step/1`)}`">
          <div class="btn btn-1 btn-full">
            Me connecter
          </div>
        </a>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="text-center" cols="12">
        Vous êtes connecté
        <span v-if="user.first_name">en tant que {{ user.first_name }} {{ user.name }}</span>
      </v-col>
      <v-col class="text-center" cols="12">
        <div class="btn btn-1 btn-full" @click="logout">
          Me déconnecter
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="userError" cols="12"><p class="error--text text-center">{{ userError }}</p></v-col>
      <v-col cols="6">
        <v-text-field
          v-model="customer.name"
          :rules="defaultRules"
          label="Nom"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="customer.first_name"
          :rules="defaultRules"
          label="Prénom"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="customer.email"
          :rules="emailRules"
          label="E-mail"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="customer.tel"
          :rules="defaultRules"
          label="Téléphone"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="customer.adr1"
          :rules="defaultRules"
          label="Adresse"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="customer.adr2"
          label="Complément d'adresse"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="customer.zip_code"
          :counter="10"
          :rules="defaultRules"
          label="Code postal"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="customer.city"
          :rules="defaultRules"
          label="Ville"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="customer.country_id"
          :items="countries"
          :rules="defaultRules"
          item-text="name"
          item-value="id"
          label="Pays"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox v-model="cgvCgu" :rules="checkboxRequiredRules">
          <span slot="label">
            J'accepte la politique de confidentialité et les <a href='https://www.lesreparables.fr/cgv-cgu/'
                                                                target='_blank' @click.stop>cgv / cgu</a>
          </span>
        </v-checkbox>
        <v-checkbox v-if="partnerEngagementSentence" v-model="partnerEngagment" :rules="checkboxRequiredRules">
          <span slot="label">{{ partnerEngagementSentence }}</span>
        </v-checkbox>
        <v-checkbox
          v-model="customer.newsletter_subscribed"
          label="J'accepte de recevoir des actualités des Réparables (pas de spam, nous vous envoyons des nouvelles une fois par mois 😉)"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {mapMutations, mapState} from "vuex";
import axios from "axios";
import config from "../../config";

export default {
  data: () => ({
    defaultRules: [
      v => !!v || 'Ce champs est requis'
    ],
    emailRules: [
      v => !!v || 'Ce champs est requis',
      v => /.+@.+\..+/.test(v) || 'Le format n\'est pas celui d\'un email',
    ],
    checkboxRequiredRules: [
      v => !!v || 'Vous devez accepter les conditions'
    ],
    partnerEngagment: false,
    countries: [{id: 1, name: 'France'}],
    accountHost: config.accountHost,
    currentHost: window.location.origin,
    userError: null
  }),
  props: {
    customer: {
      type: Object,
      required: true
    },
    cgvCguAccepted: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    'customer.country_id': function (id) {
      this.customer.country_code = this.countries.find((country) => country.id === id)?.code_iso;
    },
    '$route.query': {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value.token) {
          this.setUser({
            access_token: value.token
          })
        }
      }
    }
  },
  computed: {
    ...mapState('flux-app', ['flux']),
    ...mapState('environment-app', ['environment']),
    ...mapState('user-app', ['user']),
    cgvCgu: {
      get() {
        return this.cgvCguAccepted
      },
      set() {
        this.$emit('cgvCguAcceptedToggle')
      }
    },
    partnerEngagementSentence() {
      if (this.flux.brand === 'jott') {
        return "Je m’engage à envoyer une doudoune JOTT"
      }

      return this.environment.partnerEngagementSentence
    }
  },
  methods: {
    ...mapMutations('user-app', ['setUser']),
    logout() {
      this.setUser({})
      this.$emit('reset')
    }
  },
  created() {
    axios({
      url: `${config.apiHost}/countries`,
      responseType: 'json',
      validateStatus: () => true
    }).then(async (response) => {
      this.countries = response.data.countries.sort((a, b) => a.name.localeCompare(b.name))
    })

    if (this.user?.access_token) {
      axios({
        url: `${config.apiHost}/member/me`,
        responseType: 'json',
        headers: {
          'Authorization': `Bearer ${this.user.access_token}`,
          Accept: 'application/json',
        }
      }).then(async (response) => {
        this.setUser({...this.user, name: response.data.name, first_name: response.data.first_name})
        this.customer.name = response.data.name
        this.customer.first_name = response.data.first_name
        this.customer.email = response.data.email
        this.customer.tel = response.data.tel
        this.customer.adr1 = response.data.adr1
        this.customer.adr2 = response.data.adr2
        this.customer.zip_code = response.data.zip_code
        this.customer.city = response.data.city
        this.customer.country_id = response.data.country?.id
        this.customer.newsletter_subscribed = response.data.newsletter_subscribed
      })
        .catch((e) => {
          if (e.response && e.response.status === 401) {
            this.logout()
            return
          }

          this.userError = 'Une erreur est survenue lors de la récupération des informations de votre compte, vos informations ne peuvent pas être pré-remplies.'
        });
    }
  }
}
</script>

<style scoped>
a {
  font-weight: bold;
  text-decoration: underline;
}

.error--text a {
  color: #ff5252;
}

@media (min-width: 768px) {
  #livraison {
    padding-left: 16px;
  }
}
</style>

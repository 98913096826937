<template>
  <div id="resume">
    <h2 class="hmodal">RESUMÉ DE LA COMMANDE</h2>

    <div v-if="cards.totalTTC" class="text-center">
      <template v-if="cards.totalBonus">
        <div class="mb-5" style="display: flex; justify-content: center">
          <img id="bonus" alt="Logo bonus" class="mr-5" src="@/assets/bonus.png"/>
          <div>
            <div class="text-center">
              Total : {{ formatPrice(cards.totalTTC + cards.totalBonus) }}
            </div>
            <div class="text-center">
              Bonus réparation : -{{ formatPrice(cards.totalBonus) }}
            </div>
          </div>
        </div>
        <div class="text-center">
          Total de vos réparations : {{ formatPrice(cards.totalTTC) }}
        </div>
      </template>
      <div v-else class="text-center">
        Total de vos réparations : {{ formatPrice(cards.totalTTC) }}
      </div>
      <div v-if="cards.shippingInfo">
        Livraison (aller / retour) : {{ formatPrice(cards.shippingInfo.value) }}
      </div>
      <div v-if="cards.insurance">
        Protection transport : {{ formatPrice(cards.insurance.price) }}
      </div>
      <div v-if="cards.totalWithPort">
        <template v-if="cards.totalWithPortR!==null">
          <b>TOTAL À PAYER : {{ formatPrice(cards.totalWithPort) }}</b><br/><br/>
          <b>TOTAL À PAYER SUITE REMISE : {{ formatPrice(cards.totalWithPortR) }}</b>
          <p>
            Vous avez utilisé votre code promo : {{ cards.infoCode.coupon }}
            <template v-if="isPromoRepairsOnly">(applicable uniquement sur les réparations)</template>
          </p>
        </template>
        <template v-else>
          <b>TOTAL À PAYER : {{ formatPrice(cards.totalWithPort) }}</b>
        </template>
      </div>

      <template v-if="cards.shippingInfo">
        <hr/>
        <div v-if="cards.shippingInfo">
          <b>LIVRAISON : {{ cards.shippingInfo.titre }}</b><br/>
          <a
            v-if="cards.shippingInfo.details || (cards.shippingInfo.description && cards.shippingInfo.description.length > 50)"
            class="text-center"
            @click="showDetails = !showDetails"
          >
            Détails
            <v-icon small>{{ showDetails ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
          </a>
          <div
            v-if="showDetails || (!cards.shippingInfo.details && (!cards.shippingInfo.description || cards.shippingInfo.description.length <= 50))"
            v-html="cards.shippingInfo.description"
          />
          <div v-if="showDetails" v-html="cards.shippingInfo.details"></div>
        </div>
      </template>
    </div>

    <hr v-if="error"/>
    <div v-if="error" class="text-center red--text">
      {{ error }}
    </div>

    <hr v-if="step < 3"/>
    <div class="action text-center">
      <div
        v-if="step < 3"
        id="next-btn"
        :class="{error: isNextStepDisabled}"
        class="btn btn-1 btn-full"
        @click="!isNextStepDisabled && $emit('next-step')"
      >
        <spinner v-if="isNextStepLoading"/>
        <span v-else>ETAPE SUIVANTE</span>
      </div>
      <br/><br/>
      <div v-if="step > 1 && step < 3" class="btn btn-2 btn-full" @click="$emit('prev-step')">ETAPE PRECEDENTE
      </div>
      <router-link v-else-if="step === 1" class="btn btn-2 btn-full" to="/">REVENIR AU CALCULATEUR</router-link>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/shared/Spinner";
import {formatPrice} from "@/helper/PriceHelper";

export default {
  name: "Resume",
  methods: {
    formatPrice
  },
  components: {Spinner},
  props: {
    cards: {
      type: Object,
      required: true
    },
    step: {
      type: Number,
      required: true
    },
    isNextStepLoading: {
      type: Boolean,
      required: true
    },
    isNextStepDisabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      showDetails: false
    }
  },
  computed: {
    isPromoRepairsOnly() {
      return this.cards?.infoCode?.repairs_only
    }
  }
}
</script>

<style scoped>
#resume {
  background-color: #eeeeee;
  padding: 10px;
  height: 100%;
}

@media (min-width: 768px) and (max-width: 959px) {
  #resume {
    min-height: calc(100vh - 190px - 130px);
    height: 100%;
  }
}

@media (min-width: 960px) {
  #resume {
    min-height: calc(100vh - 205px - 160px);
  }
}

#next-btn.error {
  cursor: no-drop;
  background-color: #c5c5c5 !important;
  border-color: #c5c5c5 !important;
}
</style>

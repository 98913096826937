<template>
  <div>
    <h4>PROTECTION TRANSPORT</h4>
    <v-bottom-sheet
      v-model="sheet"
      inset
      max-width="700"
      @input="fetchInsurances"
      @click:outside="onClose"
    >
      <template v-slot:activator="{ on }">
        <div id="insurance-container" class="border-dark border mb-5 text-center" v-on="on">
          <span
            class="d-flex justify-space-around w-100"
            style="max-width: 300px; margin: auto"
            v-html="getFormattedInsurance(cards.insurance)"
          />
        </div>
      </template>
      <v-sheet class="text-center p-4">
        <h4 class="mb-4">PROTECTION TRANSPORT</h4>
        <p>
          Souhaitez-vous ajouter une protection pour le transport de votre colis ?
          <v-btn class="btn-invisible" @click="showModal = true">
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </p>
        <modal v-show="showModal" type="insurance" @close="showModal = false"/>
        <v-radio-group v-model="selected" style="margin-left: 15%">
          <template v-if="insurances === null">
            <v-skeleton-loader v-for="i in 3" :key="i" class="mb-4" type="heading"/>
          </template>
          <template v-else>
            <v-radio
              v-for="insurance in insurances"
              :key="insurance.label"
              :value="insurance.id"
            >
              <template v-slot:label>
                <span class="d-flex justify-space-between w-75" v-html="getFormattedInsurance(insurance)"/>
              </template>
            </v-radio>
          </template>
          <v-radio :value="null">
            <template v-slot:label>
              <span class="d-flex justify-space-between w-75" v-html="getFormattedInsurance()"/>
            </template>
          </v-radio>
        </v-radio-group>
        <div :class="{ disabled: isSubmitting}" class="btn btn-1 btn-full mt-3" @click="onSubmit">
          <spinner v-if="isSubmitting"/>
          <span v-else>Valider</span>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import Spinner from "@/components/shared/Spinner.vue";
import config from "../../../config";
import axios from "axios";
import Bugsnag from "@bugsnag/js";
import Modal from "@/components/Modal.vue";

export default {
  name: "Insurance",
  components: {Modal, Spinner},
  data() {
    return {
      sheet: false,
      selected: null,
      isSubmitting: false,
      insurances: null,
      showModal: false
    }
  },
  created() {
    this.resetSelected()
  },
  computed: {
    ...mapState('cart-app', ['cards'])
  },
  methods: {
    ...mapMutations('cart-app', ['setInsurance']),
    ...mapMutations('insurance-app', ['setInsurances']),
    resetSelected() {
      this.selected = this.cards.insurance?.id ?? null
    },
    onClose() {
      this.resetSelected()
    },
    fetchInsurances() {
      if (this.insurances !== null) {
        return
      }

      axios({
        url: `${config.apiHost}/insurances`,
        responseType: 'json',
      }).then(response => {
        this.insurances = response.data.insurances
        this.setInsurances(response.data.insurances)
      })
    },
    getFormattedInsurance(insurance) {
      if (!insurance) {
        return 'Aucune protection'
      }

      return `${insurance.label} <strong>+${insurance.price}€</strong>`
    },
    async onSubmit() {
      if (this.isSubmitting) {
        return false
      }

      if (this.selected === (this.cards.insurance?.id ?? null)) {
        this.sheet = false
        return
      }

      this.isSubmitting = true

      const url = `${config.apiHost}/orders/${this.cards.orderId}/insurance`
      try {
        await axios({
          url,
          method: 'PATCH',
          responseType: 'json',
          data: {
            insurance: this.selected
          },
        })

        this.setInsurance(this.insurances.find(item => item.id === this.selected) ?? null)
        this.$emit('selected')
        this.sheet = false
      } catch (e) {
        Bugsnag.notify(JSON.stringify({
          url,
          status: e.response.status,
          response: e.response.data,
          data: {
            insurance: this.selected
          }
        }))
      }

      this.isSubmitting = false
    }
  }
}
</script>

<style>
#insurance-container {
  border-radius: 5px;
  cursor: pointer;
  border-color: #e6e6e6 !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02);
  padding: 10px;
  font-size: 16px;
}

.v-skeleton-loader__heading {
  width: 80% !important;
}

@media (min-width: 600px) {
  .v-dialog.v-dialog--active.v-bottom-sheet.v-bottom-sheet--inset {
    position: relative;
    bottom: 50%;
  }
}
</style>
